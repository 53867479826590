import axios from 'axios';
import axiosConfiguration from '@/middleware/api/utils/axiosConfiguration';
import reportErrorToSentry from '@/middleware/api/utils/reportErrorToSentry';
import endpoints from '@/middleware/api/utils/endpoints';

const { listBeneficiaries } = endpoints.subscriptions;

const DELAY = 500;

/**
 * Helper to delay execution.
 */
export function delayExecution(callback) {
  return new Promise((resolve) => setTimeout(() => resolve(callback()), DELAY));
}

/**
 * Helper for safe axios GET requests.
 */
export async function safeGet(url, token, userInfo) {
  try {
    return await axios.get(url, axiosConfiguration(token));
  } catch (error) {
    reportErrorToSentry(error, userInfo);
    throw error;
  }
}

/**
 * Helper for safe axios POST/PUT/DELETE requests.
 */
export async function safeRequest(method, url, token, data = {}, headers = {}, userInfo) {
  try {
    const config = { ...axiosConfiguration(token), headers };
    return await axios({
      method, url, data, ...config,
    });
  } catch (error) {
    reportErrorToSentry(error.response?.data?.message || error, userInfo);
    throw error;
  }
}

/**
 * Helper to construct the list beneficiaries endpoint.
 */
export function constructBeneficiariesEndpoint({ walletId, bundleId, beneficiaryId = '' }) {
  const { main, subscriptions, beneficiaries } = listBeneficiaries;
  return `${main}${walletId}${subscriptions}${bundleId}${beneficiaries}${beneficiaryId}`;
}
