/* eslint-disable max-len, vue/max-len */
export default {
  POWER_BI_DASHBOARDS: {
    AV: {
      club: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiNTdmMmY4MTMtNmFhNC00ZTBiLWE5NzItZWRmYTYwY2NmYmQ4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiYmRiNmI0MDAtOWViYS00YjU5LWI3YzUtZjc4ZGFjMDRjMDM1IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        RETENTION: 'https://app.powerbi.com/view?r=eyJrIjoiZGUyZTgyZTAtOTdiMC00NWYzLWIwYTUtMDhkMGVjNTMwMTgzIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        FINANCE: 'https://app.powerbi.com/view?r=eyJrIjoiYjliZTQ0NGQtM2VmNi00NDViLTg2NGItMDI4NTcwZjQxNTYyIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
    },
    J9: {
      club: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiMzgxNzJjN2QtZWU1OC00YTM2LWJhYzctNGQ5YThjNDdiNjJkIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiNjQzY2IzMDktYmJjZS00MjFiLWI4YmQtZWQxMzIwOTg0ZDFiIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        RETENTION: 'https://app.powerbi.com/view?r=eyJrIjoiMjllOGUzNDgtMjQ1ZC00NzBhLTkwYWYtZGNlNThjYzg4MjExIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        FINANCE: 'https://app.powerbi.com/view?r=eyJrIjoiNTAxNzVjN2UtY2JmNS00ZjhhLTliNWQtYTdlNWE1NGY1MzYwIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
    },
    KZR: {
      club: {
        MONTHLY_EXECUTIVE_DASHBOARD: 'https://app.powerbi.com/view?r=eyJrIjoiMTNjOTIwMjEtMmVkOS00OTYwLWFkZDEtNTY4NTdhNzk3Zjg1IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        DAILY_EXECUTIVE_DASHBOARD: 'https://app.powerbi.com/view?r=eyJrIjoiYzAyZThhZTAtMTRhMC00ZmM4LTk3MDktMDA1NzM2NGI4ZDQ2IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=15f88e3116db36032940',
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiOGU4OGM3ZDQtMjU5Yi00ZWQzLTk4NTItZWNmOGM0NjU3YTM3IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33 ',
      },
    },
    SV: {
      subscription: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiNzRiMTBmNjAtNGM1OC00ZjkwLTg5NTMtYjM3Y2E0MzI4ODlhIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=6bd5c3c9a6ca98b11191',
        BOOKING: 'https://app.powerbi.com/view?r=eyJrIjoiNWNhMjhiZjgtYWZjYi00ZTU4LTg0YjgtYWM2M2ZmZTA4MmI3IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        FINANCE: 'https://app.powerbi.com/view?r=eyJrIjoiMTRiYTA5MTUtN2ZmMy00ZmUzLWFhMWMtYTZkYTY4YThmNzliIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiZmU2ZTJiYWItZDgzMi00OGMzLWFiZGEtYzc2ZWQ0NjM4ZTc4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        INCREMENTAL_REVENUE: 'https://app.powerbi.com/view?r=eyJrIjoiZTRjZWJlYzItMDdmYi00ZTQ5LWJjMmEtNzQyZjUyNTczZjA2IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=96001df62e58d317ec24',
        USERS: 'https://app.powerbi.com/view?r=eyJrIjoiYTAwYWM3OGYtOTZjZS00MGI4LTg3YmYtNDk4ZjcxMjdmZjg5IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
    },
    W6: {
      subscription: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiZmQyYTE4ZGYtODdlMi00YmFkLWJiZDMtN2VmZWFmNjkzNGRjIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=6bd5c3c9a6ca98b11191',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiZjkzNWQwZDQtM2Y0NC00ZTgyLThhYmQtNzY5ZmIzY2ZmZDE2IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        RETENTION: 'https://app.powerbi.com/view?r=eyJrIjoiNTg3NmIzMmUtZDY4NC00Y2FjLWJjODUtNzM0NjhmYmM0YWQ3IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        BOOKING: 'https://app.powerbi.com/view?r=eyJrIjoiNmYyYzQ4MWMtODg4OC00MDJjLTlhM2QtNmU0NWY0ZjQ2YTYyIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        INCREMENTAL_REVENUE: 'https://app.powerbi.com/view?r=eyJrIjoiN2U3NGUxYzYtNDY1My00OTVhLTlkZTItM2E4ZDRkNzliZDA2IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=96001df62e58d317ec24',
        BILLING: 'https://app.powerbi.com/view?r=eyJrIjoiMTIzOWQ1MmYtZDY5OS00YzZhLTkwY2QtYzQ4NWJmNThjNTY0IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
      unlimited: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiMGFlMDhjNzEtMmYwMi00YWQzLWE1NjAtZWM3ZGI0YzY4YjUzIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiYTg1ZjJhOGMtMThjZC00MjQ5LWExMzUtYWM0MWVhZGUyNzQyIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        RETENTION: 'https://app.powerbi.com/view?r=eyJrIjoiNmFhMDhhMTgtNWE2NC00ODgwLWE1ZDgtNWM1NmMyMmMyMzFlIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        BOOKING: 'https://app.powerbi.com/view?r=eyJrIjoiZWQ5MThlOTQtNTI4OS00NjllLWI5NTUtNDk5NDcwYTdiN2Y1IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        BILLING: 'https://app.powerbi.com/view?r=eyJrIjoiMzk0Mjc2MjAtNmVmZS00MjBjLThjZWYtNWFhMTA5Yzg4MzE3IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
    },
    Y4: {
      club: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiZGI2OGVkZWMtYjI4NC00ODcwLTgwYjAtMDIwMWFlYjAzYjcxIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiMWUwZmUzYWItOWE4Ny00YzJkLWI2MGMtZGYzZWE0MzBlMTcxIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        RETENTION: 'https://app.powerbi.com/view?r=eyJrIjoiYjhhZTkzY2MtMDA5ZS00YjVkLWEwNTQtOGY4YjFkZDMyZTBhIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        FINANCE: 'https://app.powerbi.com/view?r=eyJrIjoiODVkODUwMWQtZjJkNy00YTIyLTlkZDUtN2U3YTIyMmJlN2YzIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
      subscription: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiMTNjYjIzMzMtZDdhYy00OWMzLWFhMDktMDdjYTA4N2I0OTg5IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=6bd5c3c9a6ca98b11191',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiYzQ1ODU0YmQtODU4OC00YzhlLTllZDAtZDhjN2U0NDJkMTg0IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        RETENTION: 'https://app.powerbi.com/view?r=eyJrIjoiZjA2NTE1NmMtNTY2ZC00Yzc2LTljYTctMzNlYTRiNDFmODAzIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        BOOKING: 'https://app.powerbi.com/view?r=eyJrIjoiN2M1YmU3YjEtNjVjNC00NmQ5LWExMDUtMTRjMGRlNzRiZjM4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        INCREMENTAL_REVENUE: 'https://app.powerbi.com/view?r=eyJrIjoiYWQ3YjJjNjctOTIxMC00YmRjLWFlMmMtNTgzNjdmMzgzZGY4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=96001df62e58d317ec24',
        BILLING: 'https://app.powerbi.com/view?r=eyJrIjoiZWUwZWIzOWQtZTk1OC00OGUzLThlNzgtNjM4MjYyNjY2NWNkIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
      unlimited: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiNTA4MWFmMDItNGU3Yy00MmFjLWJmODktZmY3ZmIzNzJhMzYzIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiZTg1ZWNkMmQtZjQ0MC00ZTY0LWFjZjMtODcwNjczNjFlOGEyIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        RETENTION: 'https://app.powerbi.com/view?r=eyJrIjoiOGE0NjRlMTgtYmQ5ZC00Zjc0LWE3MWItZmFiMGVlODAyODEyIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        BOOKING: 'https://app.powerbi.com/view?r=eyJrIjoiMzM2N2NmZjQtYTQ3ZC00ZjczLWI0ZmEtMTExZDg1YTFlN2E5IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        BILLING: 'https://app.powerbi.com/view?r=eyJrIjoiOWEzNDM3YjItOGI5Ny00NjZmLTk5NjAtOWI0N2I5YjQ0ODJhIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
    },
    F3: {
      subscription: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiY2QwOTlmMDEtMzI0MC00OWZjLTg1MjQtZTc4M2I1ZDZjMDA2IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=6bd5c3c9a6ca98b11191',
        BOOKING: 'https://app.powerbi.com/view?r=eyJrIjoiZjA4NjVhMjktOTdlNi00Mjg2LWEzOTEtYjBmNTBkNTdlODg4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        FINANCE: 'https://app.powerbi.com/view?r=eyJrIjoiNzRiZTk5ZTQtZjJjNy00Y2RkLTg1ZmQtYzdjZjYyNjg5ZTg1IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiZjM1YWJlOWYtNjc5Mi00NzA4LWEzNzctOWFmNTEwNzBhNTRjIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=ReportSectioncbc1f510b771907b9b33',
        INCREMENTAL_REVENUE: 'https://app.powerbi.com/view?r=eyJrIjoiYTM5MjA5NGUtNDY0ZC00ZDkzLTkwNzItZDRmYWNlZWJjZmE4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=96001df62e58d317ec24',
        USERS: 'https://app.powerbi.com/view?r=eyJrIjoiYzc3M2IzMzEtZThlMC00ZjA1LWFiZTAtN2RmNTgzNzVjMjEyIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9&pageName=f93458f7dcf063c96eb2',
      },
    },
    JA: {
      subscription: {
        ACQUISITION: 'https://app.powerbi.com/view?r=eyJrIjoiYWFiNzU0OWQtM2U5NC00ZDZiLTg1YTQtNmUwNTk0MWE3NDA0IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9',
        BOOKING: 'https://app.powerbi.com/view?r=eyJrIjoiNzA5MzkzMzQtZDMzYi00ZTY5LTgyMzMtY2I5Nzk5ZDcxNDY4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9',
        FINANCE: 'https://app.powerbi.com/view?r=eyJrIjoiMWJhOWQxNzgtZTlkOC00Y2YwLWEzMzItODcwMjUzZTk4MWJmIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9',
        GROWTH: 'https://app.powerbi.com/view?r=eyJrIjoiZTY1NmVhZjktOTQwZC00ZWRmLWI2YTgtYzVjOGNkYmM4OTNhIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9',
        INCREMENTAL_REVENUE: 'https://app.powerbi.com/view?r=eyJrIjoiZjljM2NkMjktZDA0My00ZjRjLTkxMGQtNzliNTM5NzExZWY4IiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9',
        USERS: 'https://app.powerbi.com/view?r=eyJrIjoiNGIzYjNlMTYtYzZiYS00ODM0LTg4Y2UtZTU2ODAwNDU5ZWZjIiwidCI6IjYxNDgwODNiLTJjNTMtNDNjOC1iNDJkLTQwMTMwZjYzOGE1NyIsImMiOjl9',
      },
    },
  },
  // LEGACY DASHBOARDS:
  DASHBOARD: {
    FA: {
      INTEGRATION_ID: 'd9c37326-f593-44ff-8d88-71f3fbacd300',
      TYPES: {
        GENERAL: {
          dashboardId: 'f41ca9d2-1b09-4653-8c50-b9f7e6d47924',
        },
        LIVE_DATA: {
          dashboardId: '259865bc-ebec-45f2-bd77-1116e3c779d1',
        },
      },
    },
    AS: {
      INTEGRATION_ID: '4f3ae1d8-126d-4086-a774-68e41dffb291',
      TYPES: {
        EXECUTIVE_DASHBOARD: {
          dashboardId: 'b67e30ad-3b19-47f4-8989-a5f3b62dda7b',
        },
        USERS_AND_ENGAGEMENT: {
          dashboardId: '3e06f3d9-25af-49c0-8ccf-f4e22e98f53a',
        },
        REVENUE: {
          dashboardId: '7dcc3cd4-6f5d-42c4-97c5-0b9a314a5a0c',
        },
        BOOKING: {
          dashboardId: '3ed7f4d1-0cd0-4353-b4ff-52daa5cde329',
        },
        ACQUISITION: {
          dashboardId: '898685d4-ae52-47f2-a670-7e682d51a69f',
        },
        LIVE_DATA: {
          dashboardId: '289a5e54-e93d-4744-b4d6-d5cf46f7efc6',
        },
      },
    },
    VH: {
      INTEGRATION_ID: 'f7d6ac96-55d4-4c96-853d-116e5f8e1dc9',
      TYPES: {
        GENERAL: {
          dashboardId: '242888cb-03cb-4a03-a05f-dd2003f90ea9',
        },
        LIVE_DATA: {
          dashboardId: 'c48065cb-8e9b-43ad-923a-a1f51b38abfd',
        },
      },
    },
    '0B': {
      INTEGRATION_ID: 'f2ddd600-05b1-447c-9191-775a2e5c5409',
      TYPES: {
        LIVE_DATA: {
          dashboardId: '3c6d172c-b5f1-4308-b83c-30d04b61d48c',
        },
      },
    },
    WO: {
      INTEGRATION_ID: 'ad8d5d75-cee8-4c96-9f00-b219568722f6',
      TYPES: {
        GENERAL: {
          dashboardId: '2036426d-97ae-4290-8352-ee0bd502aa41',
        },
      },
    },
  },
};
